import React, { useEffect } from "react";

export default function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="docs">
      <div className="docs__heading">
        <h2>Privacy Policy</h2>
        <p>
          We takes the privacy of its users very seriously. We are aware that
          keeping our treasured clients` private information is vital and have
          made the trustworthiness and security of the platform into one of our
          top priorities.
        </p>
        <p>
          We advise you to familiarize yourself with our privacy protocol and
          let us know in case you have any inquiries. The arrangements stated in
          this protocol are applicable to current and previous users as well.
        </p>
      </div>
      <div className="docs__content">
        <div className="docs__content-flex">
          <div className="docs__content-flex-box">
            <h2>1. Private information.</h2>
            <p>
              At the time activating of your profile at vra-berlin, we would
              request that you share the necessary personal info, in order to
              provide you the optimum trading experience. We need the info to
              make the necessary transfers and trades, to determine your
              financial scope, to handle any forgotten password issues, make
              customer care easier and let you know about any Forex related
              matter that might concert you. We are also obliged by the law to
              keep proper records.
            </p>
            <h2>
              2. We might request that you share <br />
              the following thing with us:
            </h2>
            <p>
              In order to apply: your name, DOB, a form of identification or
              social security, proper address. We might also need to make sure
              of your occupation, financial status, and more relevant
              information, as described during the process of creating a
              profile. In order to make transfer funds – statements or copies of
              previous correspondence and details about your transfers with
              Sekundex or its partners, along with any trading actions,
              financial statements, previously asked questions and the
              respective answers.
            </p>
            <p>
              For authentication purposes – we might need certain documents for
              making sure that the information represents the real person (some
              form of personal identification) and also bank statements, utility
              statements, bank card information and so on. Attention: vra-berlin
               is obliged by the AML and the USA Patriot Act to gather any
              data if there is a need and act accordingly to verify a user`s
              identification.
            </p>
            <p>
              Attention: vra-berlin xwants to clarify that it does not
              purposefully keep or gather any information of any individuals
              under the age of thirteen.
            </p>
            <p>
              If it comes to the knowledge of vra-berlin that information has
              been collected from a person under the age of thirteen, then the
              appropriate measures will be taken to erase that information.
              Please, if any parents or attendants of children learn that their
              child has submitted private/personal information with no
              permission, write us immediately.
            </p>
          </div>
          <div className="docs__content-flex-box">
            <h2>3. Utilization of cookies</h2>
            <p>
              Some information is being transmitted from the platform`s server
              to the user`s browser and kept on the user`s device. This is
              referred to as cookies. vra-berlin gathers necessary data by
              using the cookies. Don`t worry, as cookies don`t have any private
              information regarding the user in them, with the exception when
              the user has provided such information by himself – like when
              opening a profile at a website. vra-berlin will utilize the
              cookies for the purpose of identifying and quantifying platform
              traffic, along with measuring how certain services and ads are
              performing.
            </p>
            <p>
              This way vra-berlin can better gauge the ways in which the users
              are utilizing the platform, and make enhancements to the
              performance of the website. Additionality, it lets us know what
              kind of relevant materials and information we should provide to
              you. The collected data might also be made available to vra-berlin
              staff, partners, and associates. vra-berlin will not use
              the information for commercial purposes by providing it to any
              unassociated individuals or entities.
            </p>
            <p>
              When the user goes to vra-berlin , there might be other
              affiliated parties that also place their cookies on the user`s
              computer. We would advise you to take a look at the terms and
              conditions of those affiliate parties` websites in case you have
              any questions or issues regarding their placement of cookies.
              Usually, the usage of cookies is the same as on vra-berlin .
            </p>
            <h2>4. Disclosure information.</h2>
            <p>
              Some information might be disclosed fully or partially with our
              trusted partners. The purpose of this is to be able to let
              customers know of any new offers or functionalities, along with
              providing assistance with user profiles. The partners consist of
              partiers related to the platform, who are usually stakeholders of
              some kind. The confidentiality of your private data will be kept
              by our partners as it is being kept by vra-berlin , following the
              privacy terms and conditions stated on the platform.
            </p>
          </div>
        </div>
        <hr />
        <div className="docs__content-flex">
          <div className="docs__content-flex-box">
            <h2>5. Other Parties.</h2>
            <p>
              With the exception of the parties stated in the privacy protocol,
              vra-berlin will not share your data with any other parties.
              Sometimes we might give information to non-associated firms
              responsible for performing maintenance on the user`s profile. This
              includes parties that take care of the fund`s transfer process at
              vra-berlin . Additionally, it might include firms responsible for
              any legal and financial matters at vra-berlin . Any party which
              is not a direct association to vra-berlin should keep the
              provided user data to the same degree in which it is received. The
              non-associate party can only utilize the given user data in order
              to provide the specified service and for no other reasons. In some
              cases, vra-berlin might give out a clients` private data to
              another party given that there are certain instructions that need
              to be accomplished. Know that vra-berlin would never use your
              information as a source of revenue and give it in exchange for
              money. In case of any requests and control by bodies of the law,
              vra-berlin might share private user data given to other parties.
              The shared information might contain private specifics in order to
              meet the requirements of the law, as well as securing the rights
              and integrity of the platform.
            </p>
            <h2>6. Consolidation and insolvency.</h2>
            <p>
              All private client data will be consolidated following the
              platform, in case there is a merger of vra-berlin . Stated in the
              inclusion terms and the obligations for keeping records, there are
              going to be different procedures in case of insolvency.
            </p>
            <h2>7. Payment details.</h2>
            <p>
              vra-berlin does not hold any data related to client`s credit card
              information and details. In case you`re asked to give a copy of
              your bank card, you should only provide the ending 4 digits of the
              card number, without giving out the CVC which can be found on the
              back of the card.
            </p>
          </div>
          <div className="docs__content-flex-box">
            <h2>8. Inability/Unwillingness to share private data.</h2>
            <p>
              You can choose if you want to share the necessary information with
              vra-berlin . Be mindful though, that any info you omit might lead
              to issues with your trading profile. It might also have an impact
              on the services we`re able to offer you.
            </p>
            <h2>9. Pulling out.</h2>
            <p>
              If you wish for vra-berlin to delete all your private information
              from the platform and the servers, you will need to send us a
              detailed message. Know that any omitted data might result in
              issues with your profile and consequently lead to inadequacy with
              the platform services.
            </p>
            <h2>10. Corrections.</h2>
            <p>
              If any corrections are made, which change the policies and
              protocol for private data gathered by vra-berlin , the clients
              will be promptly informed about it. The client has the right to
              decide whether his private data will be used in a certain way. Any
              other change or correction which is not related to a client`s
              private data will be posted on the platform.
            </p>
            <h2>11. Mailing info.</h2>
            <p>
              In case you have any inquiries regarding the privacy protocol or
              something related to the platform, please feel free to contact us
              at any time. You can send us an e-mail, call us or fax us. There
              is always a dedicated vra-berlin employee to answer your
              questions.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
